<template>
  <div style="max-width: 500px" class="mt-15 pt-15 px-3 ma-auto earn-redeem">
    <h2 class="pb-5 text-center text-capitalize secondary--text">
      {{ `${$t('label.brandName')} ${$t('label.verifyEarn')} ` }}
    </h2>
    <div class="py-10 secondary rounded white--text">
      <div class="text-center pb-5">
        <img src="/assets/image/logo.png" alt="logo" width="200px" />
        <div style="font-size: 12px" class="text-uppercase">
          {{ $t('label.slogan') }}
        </div>
      </div>
      <div class="infoborder">
        <div class="px-8" v-for="(i, index) in earnDetailInfo" :key="index">
          <v-row
            no-gutters
            class="pb-5 align-center text-capitalize"
            :style="i.style"
            :class="i.class"
            :color="i.color"
            v-if="!i.isHidden"
          >
            <v-col cols="5">{{ $t(`label.${i.name}`) }}</v-col>
            <v-col cols="7" class="font-weight-bold text-right">
              <div v-if="i.key !== 'billAmount'">
                {{ earnDetailObj[i.key] }}
              </div>
              <div v-else>
                {{ 'RM ' + $route.query.total_price }}
              </div>
              <div
                v-if="
                  i.key == 'merchantName' &&
                  earnDetailObj.earnUseType == VoucherUseType.MERCHANT
                "
              >
                {{ earnDetailObj.branchName }}
              </div>
              <div v-if="i.key == 'merchantName'">
                {{ $route.query.branch_name}}
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="this.voucher_discount > 0" class="px-8">
          <v-row
              no-gutters
              class="pb-5 align-center text-capitalize red--text font-weight-bold"
          >
            <v-col cols="5">{{ $t(`label.voucherDiscount`) }}</v-col>
            <v-col cols="7" class="font-weight-bold text-right">
              {{ '- RM ' + this.voucher_discount }}
            </v-col>
          </v-row>
        </div>
        <div v-if="$route.query.point" class="px-8">
          <v-row
              no-gutters
              class="pb-5 align-center text-capitalize red--text font-weight-bold"
          >
            <v-col cols="5">{{ $t(`label.pointUse`) }}</v-col>
            <v-col cols="7" class="font-weight-bold text-right">
              {{ '- RM ' + $route.query.point }}
            </v-col>
          </v-row>
        </div>
        <div class="px-8">
          <v-row
              no-gutters
              class="pb-5 align-center text-capitalize grey--text font-weight-bold"
          >
            <v-col cols="5">{{ $t(`label.receivedPayment`) }}</v-col>
            <v-col cols="7" class="font-weight-bold text-right">
              RM {{ total_price }}
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-if="!earnDetailObj.countdownTime">
        <div
          v-if="earnDetailObj.status !== 2"
          class="px-10 text--white font-weight-bold text-center"
        >
          <div
            class="ma-auto"
            v-if="earnDetailObj.earnUseType == VoucherUseType.REFERRAL"
          >
            <div class="pt-5 pb-3 text-uppercase">
              {{ $t('label.branchName') }}
            </div>
            <div v-if="this.$route.query.branch_name">
              <input
                v-model="branchName"
                :placeholder="this.$route.query.branch_name"
                class="inputPin branchName"
                disabled
                type="text"
              />
              <div v-if="erroMsg" class="red--text">
                {{ erroMsg }}
              </div>
            </div>
            <div v-else>
              <input
                v-model="branchName"
                class="inputPin branchName"
                type="text"
              />
              <div>This field is required.</div>
            </div>
          </div>
          <div class="pt-5 pb-3 text-uppercase">{{ $t('label.enterPin') }}</div>
          <div class="ma-auto">
            <CodeInput
              :loading="false"
              type="number"
              class="inputPin ma-auto"
              v-on:complete="onKeyPinComplete"
              :fields="4"
              :autoFocus="true"
              ref="code"
            />
          </div>
          <div class="red--text">
            {{ erroMsg2 }}
          </div>
          <div class="pt-5">
            *Merchant key in PIN code to verify earn redemption
          </div>
        </div>
        <div v-else class="px-8 text--white font-weight-bold text-center">
          <div class="pt-5 text-center">
            <div
              v-if="earnDetailObj.earnUseType == VoucherUseType.MERCHANT"
              class="d-flex justify-space-between align-center pb-5 yellow--text"
            >
              <div>Received Payment</div>
              <div>{{ total_price }}</div>
            </div>
            <div>*Earn redemption completed</div>
            <div>{{ earnDetailObj.redemptionAt }}</div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-center pt-7"
        style="font-size: 20px; font-weight: bold"
      >
        Try again in {{ countDown }} seconds
      </div>
    </div>
    <br />
  </div>
</template>

<script>
  import {
    USER_EARN_GET_USER_EARN,
    USER_EARN_UPDATE_CHECK_USER_EARN,
    USER_EARN_INITIAL_GET_USER_EARN_STATE,
    USER_EARN_INITIAL_UPDATE_CHECK_USER_EARN_STATE
  } from '@/core/store/user-earn.module';
  import common from '@/core/mixin/common.mixin';
  import { i18nHelper } from '@/core/utils';
  import CodeInput from 'vue-verification-code-input';
  import { Voucher_Use_Type } from '@/core/constants/enums';

  export default {
    name: 'VerifyRedeem',
    mixins: [common],
    components: {
      CodeInput
    },
    data: () => ({
      erroMsg: null,
      erroMsg2: null,
      branchName: null,
      countDown: null,
      voucher_discount: 0,
      total_price: 0,
      earnDetailInfo: [],
      earnDetailObj: [],
      VoucherUseType: Voucher_Use_Type
    }),
    mounted() {
      this.initialGetEarn();
    },
    computed: {
      getUserEarnComplete() {

        return this.$store.state.userEarn.userEarn.complete;
      },
      updateCheckUserEarnComplete() {
        return this.$store.state.userEarn.checkUserEarn.complete;
      }
    },
    watch: {
      getUserEarnComplete() {
        let response = this.$store.state.userEarn.userEarn;
        this.inportUserEarn(response);
      },
      updateCheckUserEarnComplete() {
        let response = this.$store.state.userEarn.checkUserEarn;
        this.inportCheckUserEarn(response);
      }
    },
    methods: {
      onKeyPinComplete(value) {
        if (this.$route.query.branch_name == '') {
          if (this.branchName == null || this.branchName == '') {
            this.erroMsg = 'This field is required.';
            this.$refs.code.values = ['', '', '', ''];
          } else {
            this.erroMsg = '';
          }
        } else {
          if (this.$route.query.branch_name) {
            this.branchName = this.$route.query.branch_name;
          } else {
            this.$route.query.branch_name = this.branchName;
          }
        }

        if (this.$refs.code.values.includes('')) {
          this.erroMsg2 = 'This field is required.';
        } else {
          this.erroMsg2 = '';
        }

        if (
          this.branchName != null &&
          this.branchName != '' &&
          !this.$refs.code.values.includes('')
        ) {
          this.updateCheckUserEarn(value);
        }
      },
      countDownTimer() {
        // Get today's date and time
        var now = new Date().getTime();
        // Set the date we're counting down to
        var countDownDate = new Date(
          this.earnDetailObj.countdownTime
        ).getTime();
        setTimeout(() => {
          // Find the distance between now and the count down date
          var distance = countDownDate - now;
          // Time calculations for days, hours, minutes and seconds
          // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          // var hours = Math.floor(
          //   (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          // );
          // var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // Output the result in an element with id="demo"

          // If the count down is over, write some text
          if (distance < 0) {
            this.initialGetEarn();
          } else {
            // Output the result in an element with id="demo"
            this.countDown = seconds;
            this.countDownTimer();
          }
        }, 1000);
      },
      inportUserEarn(response) {
        this.earnDetailObj = response.data;
        console.log(this.earnDetailObj)

        this.total_price = this.$route.query.total_price;

        if(this.earnDetailObj && this.earnDetailObj.valueAmount && this.earnDetailObj.valueType) {
          if(this.earnDetailObj.valueType == 1) {
            this.voucher_discount = this.earnDetailObj.valueAmount
          } else {
            this.voucher_discount = (this.earnDetailObj.valueAmount / 100) * this.total_price
          }
          this.total_price -= this.voucher_discount
        }

        if(this.$route.query.point) {
          this.total_price -= this.$route.query.point * 2
        }

        if (this.earnDetailObj.countdownTime) {
          this.countDownTimer();
        }
        if (this.earnDetailObj.earnUseType == Voucher_Use_Type.REFERRAL) {
          this.earnDetailInfo = [
            {
              key: 'merchantName',
              name: 'merchant'
            },
            { key: 'voucherCode', name: 'voucherNumber', isHidden: !('valueAmount' in this.earnDetailObj) },
            // {
            //   key: 'value',
            //   name: 'discountValue',
            //   class:
            //     this.earnDetailObj.status !== 2
            //       ? 'red--text font-weight-bold'
            //       : 'grey--text font-weight-bold'
            // }
          ];
        } else {
          this.earnDetailInfo = [
            {
              key: 'merchantName',
              name: 'merchant'
            },
            { key: 'billAmount', name: 'billAmount' },
            { key: 'voucherCode', name: 'voucherNumber', isHidden: !('valueAmount' in this.earnDetailObj) },
            // {
            //   key: 'value',
            //   name: 'voucherDiscount',
            //   class:
            //     this.earnDetailObj.status !== 2
            //       ? 'red--text font-weight-bold'
            //       : 'grey--text font-weight-bold'
            // },
            // {
            //   key: 'receivedPayment',
            //   name: 'receivedPayment',
            //   class: 'grey--text font-weight-bold',
            //   isHidden: this.earnDetailObj.status == 2
            // }
          ];
        }
        this.initialGetEarnDetailState();
      },
      inportCheckUserEarn(response) {
        let type;
        if (response.code == 0) {
          type = 'success';
        } else {
          type = 'error';
        }
        let title = response.message;
        let description = '';
        let buttons = [
          {
            color: 'secondary',
            text: i18nHelper.getMessage('label.ok'),
            action: () => {
              this.closeAppDialogInfo();
              this.initialGetEarn();
              if (response.code == 0) {
                // location.reload();
              }
            }
          }
        ];
        this.$refs.code.values = ['', '', '', ''];
        this.initialUpdateCheckUserEarnState();
        this.openAppDialogInfo(type, title, description, buttons);
      },
      initialGetEarn() {
        this.getEarnDetail();
      },
      getEarnDetail() {
        let data = {
          userId: this.$route.query.user_id,
          branchName: this.$route.query.branch_name,
          branchId: this.$route.query.branch_id,
          merchantId: this.$route.query.merchant_id,
          voucherCode: this.$route.query.voucher_code,
          voucherId: this.$route.query.voucher_id,
          code: this.$route.query.code
        };
        this.$store.dispatch(USER_EARN_GET_USER_EARN, { data });
      },
      updateCheckUserEarn(value) {
        let data = {
          voucherCode: this.$route.query.voucher_code,
          pinVerification: value.toUpperCase(),
          branchName: this.$route.query.branch_name,
          branchId: this.$route.query.branch_id,
          merchantId: this.$route.query.merchant_id,
          userId: this.$route.query.user_id,
          totalPrice: this.total_price,
          code: this.$route.query.code
        };

        if (value.length == 4) {
          this.$store.dispatch(USER_EARN_UPDATE_CHECK_USER_EARN, {
            data
          });
        }
      },
      initialGetEarnDetailState() {
        this.$store.dispatch(USER_EARN_INITIAL_GET_USER_EARN_STATE);
      },
      initialUpdateCheckUserEarnState() {
        this.$store.dispatch(USER_EARN_INITIAL_UPDATE_CHECK_USER_EARN_STATE);
      }
    }
  };
</script>
<style lang="scss">
  .earn-redeem {
    .rounded {
      border-radius: 20px !important;
    }

    .inputPin {
      width: 275px !important;

      .react-code-input {
        display: flex;
        justify-content: space-between;

        input {
          font-size: 25px;
          color: #000;
          background: #fff;
          border-radius: 10px;
          text-transform: uppercase;
        }
      }
    }

    .branchName {
      font-size: 15px;
      color: #000;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      text-align: center;
    }

    .infoborder {
      width: 100%;
      margin: auto;
      border-bottom: 2px dashed #fff;
    }
  }
</style>
